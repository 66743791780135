import {connect} from "react-redux";
import Channels from "../components/streamchannels/Channels";

function mapStateToProps(store : any) {
    return {
        store  : store.channels,
        channels : store.channels.data
    }
}

const ChannelsContainer = connect(mapStateToProps)(Channels);

export default ChannelsContainer;