import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";

function receiveChannels(data : any) {
    return {
        type : types.SET_CHANNELS,
        data : data
    }
}

function receiveChannelsError(errorMessage : string) {
    return {
        type : types.SET_CHANNELS_ERROR,
        data : errorMessage
    }
}

function setSelectedObject(group : object) {
    return {
        type : types.SET_CHANNELS_SELECTED_OBJECT,
        data : group
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_CHANNELS_SELECTED_OBJECT,
        data : {}
    }
}

function changeState(state : string) {
    return {
        type : types.SET_CHANNELS_CURRENT_STATE,
        data : state
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getChannelAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getChannelFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addChannelForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getChannelFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getChannel(id).then(function (res) {
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveChannelsError(error));
            reject();
        });
    });
}

function getChannelsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getChannels().then(function (res) {
        dispatch(receiveChannels(res));
    }).catch(function (error) {
        dispatch(receiveChannelsError(error));
    });
}

export function getChannels() : any {
    return function (dispatch : any) {
        getChannelsFromAPI(dispatch);
    }
}

export function deleteChannel(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteChannel(id).then(function (res) {
            dispatch(forms.setFormSuccess("Der Stream Channel wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getChannelsFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addChannel(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addChannel(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Der Stream Channel wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            getChannelsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editChannel(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editChannel(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Channel wurden erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getChannelsFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}