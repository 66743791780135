import React, {Component} from "react";
import {Header} from "semantic-ui-react";

class Home extends Component {
    render() {
        return(
            <>
                <Header as="h1" className="mainHeader">Home</Header>
                <div className="content">
                    <p>Lieber Anwender, diese Anwendung dient zur Anpassung der Titel für die hinterlegten Channels.</p>
                    <Header as="h3">Fragen, Unklarheiten oder Ideen?</Header>
                    <p>mail@ursforrer.ch</p>
                    <Header as="h3">Idee</Header>
                    <p>Urs Forrer</p>
                </div>
            </>
        );
    }
}

export default Home;