export const SET_CHANNELS = "SET_CHANNELS";
export const SET_CHANNELS_ERROR = "SET_CHANNELS_ERROR";
export const SET_CHANNELS_CURRENT_STATE = "SET_CHANNELS_CURRENT_STATE";
export const SET_CHANNELS_SELECTED_OBJECT = "SET_CHANNELS_SELECTED_OBJECT";

export const SET_FORM_PROCESSING = "SET_FORM_PROCESSING";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_FORM_SUCCESS = "SET_FORM_SUCCESS";
export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";
export const CLEAR_FORM_SUCCESS = "CLEAR_FORM_SUCCESS";
export const SET_FORM_TEMP_OBJECT = "SET_FORM_TEMP_OBJECT";
export const CLEAR_FORM_TEMP_OBJECT = "CLEAR_FORM_TEMP_OBJECT";