import axios from "axios";
import * as apiLinks from "./apiLinks";
import store from "../store";

function getAPIConfigurationSecured() {
    return axios.create({
        timeout: 1000000,
        responseType: "json",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    });
}

// Channels

export function getChannels() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.CHANNELS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteChannel(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.CHANNELS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editChannel(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.CHANNELS + "/" + values.idStreamChannel, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getChannel(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.CHANNELS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addChannel(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.CHANNELS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}