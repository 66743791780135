import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import NoMatch from "./NoMatch";
import Home from "../home/Home";
import ChannelsContainer from "../../containers/ChannelsContainer";
import About from "../about/About";

class Content extends Component {
    render(): React.ReactNode {
        return (
            <div className="main-content-wrapper">
                <div className="main-content">
                    <Switch>
                        <Route path="/channels" component={ChannelsContainer}/>
                        <Route path="/home" component={Home}/>
                        <Route path="/about" component={About}/>
                        <Route exact path="/" component={Home}/>
                        <Route path="*" component={NoMatch} status={404}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Content;