import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as channelActions from "../../actions/channelActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";


class AddChannel extends Component<IFormProps, CreateChannelFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            name: "", subtitle: "", title: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        name: Yup.string().required("* Feld ist erforderlich"),
        subtitle : Yup.string().required("* Feld ist erforderlich"),
        title : Yup.string().required("* Feld ist erforderlich"),
    });

    onCancel(): void {
        store.dispatch(channelActions.backToList());
    }

    handleSubmit(values : CreateChannelFormValues, actions : FormikHelpers<CreateChannelFormValues>) : void {
        store.dispatch(channelActions.addChannel(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Channel hinzufügen"/>
                <h2>Channel hinzufügen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="name">Name</label>
                                <Field type="text" name="name" placeholder="Ref. Kirche Dürnten"/>
                                <FormError name="name" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="title">Titel</label>
                                <Field type="text" name="title" placeholder="08. November 2020 | Gottesdienst | Sorget euch nicht!? (Mt 6,25-34)"/>
                                <FormError name="title" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="subtitle">Subtitel</label>
                                <Field type="text" name="subtitle" placeholder="Pfarrerin: Lisset Schmitt-Martinez | Musik: Christoph Küderli"/>
                                <FormError name="subtitle" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Channel erstellen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddChannel;