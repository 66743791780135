import React, {Component} from "react";

class Copyright extends Component {
    render(): React.ReactNode {
        return (
            <div className="copyright">
                <p>(c) by ref. Kirche Dürnten, Version 1.0.0 | stream.cevi.cloud</p>
            </div>
        );
    }
}

export default Copyright;