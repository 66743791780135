import React, {Component} from "react";
import {Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";

class Navigation extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            activeItem : ""
        }
    }

    static changeNavigation() : void {
    }

    handleMenuItemClick = (e: any, {name}: any) => this.setState({activeItem: name});

    render(): React.ReactNode {
        const {activeItem} = this.state;

        return (
            <div className="main-navigation" onClick={Navigation.changeNavigation}>
                <Menu stackable>
                    <Menu.Item as={ Link } to="/" name='logo' onClick={this.handleMenuItemClick}>
                        <img style={{width: '15rem'}} src="../logo.png" alt="Ref. Kirche Dürnten Logo"/>
                    </Menu.Item>
                    <Menu.Item as={ Link } key="nav-home" to="/" name='home' active={activeItem === 'home'} onClick={this.handleMenuItemClick}>
                        Startseite
                    </Menu.Item>
                    <Menu.Item as={ Link } key="nav-login" to="/channels" name='channels' active={activeItem === 'channels'} onClick={this.handleMenuItemClick}>
                        Channels
                    </Menu.Item>
                </Menu>
            </div>
        );
    }
}

export default Navigation;